// 使用JavaScript的crypto API生成UUID
export function generateUUID() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
export function getNamesOfCheckedNodes(treeData) {
  const menuCodeList = [];

  function traverseTree(node) {
    if (node.isCheck) {
      menuCodeList.push(node.menuCode);
    }
    if (node.children) {
      node.children.forEach(child => traverseTree(child));
    }
  }

  treeData.forEach(node => traverseTree(node));
  return menuCodeList;
}
export function flattenTree(tree,isChecked=true){ // 获取树形图中id
    if(Array.isArray(tree)){
        let result = [];
        if(isChecked){
            tree.forEach(node => {
                result.push(node.id);
                if (node.children && node.children.length) {
                    result = result.concat(flattenTree(node.children));
                }
            });
        }else{
            tree.forEach(node => { 
                if (node.children && node.children.length) {
                    result = result.concat(flattenTree(node.children,false));
                }else{
                    node.checked&&result.push(node.id);
                }
            });
        }
        return result;
    }else{
        return tree;
    }
}
export function flattenTreeDetail(tree,isChecked=true){ // 获取树形图中id-分配菜单树
  if(Array.isArray(tree)){
      let result = [];
      if(isChecked){
          tree.forEach(node => {
              result.push(node.menuCode);
              if (node.children && node.children.length) {
                  result = result.concat(flattenTree(node.children));
              }
          });
      }else{
          tree.forEach(node => { 
              if (node.children && node.children.length) {
                  result = result.concat(flattenTree(node.children,false));
              }else{
                  node.checked&&result.push(node.menuCode);
              }
          });
      }
      return result;
  }else{
      return tree;
  }
}
export function isNoChildrenFlattenTree(tree){ // 获取树形图中无children-id
    if(Array.isArray(tree)){
        let result = [];
        tree.forEach(node => {
            if (node.children && node.children.length) {
                result = result.concat(isNoChildrenFlattenTree(node.children));
            }else{
                result.push(node.id);
            }
        });
        return result;
    }else{
        return tree;
    }
}
export function isCheckedAllflattenTree(tree){ // 全选
    if(Array.isArray(tree)){
        let result = [];
        tree.forEach(node => { 
            if (node.children && node.children.length) {
                isCheckedAllflattenTree(node.children)
            }
            node.checked = true; 
            !node.disabled&&result.push(node)
        });
        return result;
    }else{
        return tree;
    }
}
export function isNoCheckedAllflattenTree(tree){ // 非全选-no
    if(Array.isArray(tree)){
        let result = [];
        tree.forEach(node => { 
            if (node.children && node.children.length) {
                isNoCheckedAllflattenTree(node.children)
            }
            node.checked = false; 
            result.push(node)
        });
        return result;
    }else{
        return tree;
    }
}
export function userDepartmentTree(tree){ // 用户列表架构树

  return tree.map(node => {
    // 给当前节点添加 name 属性
    node.label = node.name
    node.value = node.deptId
    if(node.children.length===0){
        delete node.children
    } 
    // 如果节点有子节点，递归处理子节点
    if (node.children) {
      node.children = userDepartmentTree(node.children);
    }
    return node;
  });
}
export function isSomeCheckedAllflattenTree(tree,someTree){ // 部分全选
    if(Array.isArray(tree)){
        let result = [];
        tree.forEach(node => { 
            if (node.children && node.children.length) {
                isSomeCheckedAllflattenTree(node.children,someTree)
            }
            if(someTree.includes(node.id)){
                node.checked=true
            }else{
                delete node.checked
            }
            result.push(node)
        });
        return result;
    }else{
        return tree;
    }
}
// 函数用于根据指定 ID 数组筛选树形数据，并返回扁平数组
export function flattenTreeByIds(tree, ids) {
    let old=ids
    let result = [];
    function traverse(nodes) {
        nodes.forEach(node => {
          // 检查当前节点的 ID 是否在指定的 ID 数组中
          if (old.includes(node.id)) {
            result.push(node);
          }
          // 如果节点有子节点，递归遍历子节点
          if (node.children) {
            traverse(node.children);
          }
        });
      }
    
      traverse(tree);
    return result;
  }
  // 函数用于根据指定 ID 数组筛选树形数据，并返回扁平数组-菜单配置
export function flattenTreeByIdsDetail(tree, ids) {
  let old=ids
  let result = [];
  function traverse(nodes) {
      nodes.forEach(node => {
        // 检查当前节点的 ID 是否在指定的 ID 数组中
        if (old.includes(node.menuCode)) {
          result.push(node);
        }
        // 如果节点有子节点，递归遍历子节点
        if (node.children) {
          traverse(node.children);
        }
      });
    }
  
    traverse(tree);
  return result;
}
  // 将最后一个岗位信息添加到children
export function jobTree(tree,deptId=null) {
    tree.forEach(node => { 
      if(node.children && node.children.length > 0){
        node.children.forEach(child => {
          if (child.deptId===node.deptId&&child.isJob === true) {
            // 检查子元素的 isJob 属性是否为 true
            child.deptPath = node.deptPath; 
            child.deptPathName = node.deptPathName; 
          }
        });
        jobTree(node.children,node.deptId)
      }
    });
    return tree;
  }
  export function removeEmptyChildren(tree) {
    // 递归函数，用于处理每个节点
    function processNode(node) {
      // 如果当前节点的 children 数组为空，则从 tree 中移除当前节点
      if (node.children && node.children.length === 0) {
        node.label=node.name
        node.value=node.id
        delete node.disabled
        
        delete node.children
      }else{
            delete node.disabled
            node.label=node.name
            node.value=node.id
      }
      
      // 如果当前节点有 children，则递归处理每个子节点
      if (node.children) {
            node.children.forEach(child => {
            delete node.disabled
            node.label=node.name
            node.value=node.id
          processNode(child);
        });
      }else{
            delete node.disabled
            node.label=node.name
            node.value=node.id
      }
    }
  
    // 开始处理树形数据的每个节点
    tree.forEach(node => {
        node.label=node.name
        node.value=node.id
      processNode(node);
    });
  
    // 返回修改后的树形数据
    return tree;
  }
  export function removeEmptyChildrenDeptId(tree) {
    // 递归函数，用于处理每个节点
    function processNode(node) {
      // 如果当前节点的 children 数组为空，则从 tree 中移除当前节点
      if (node.children && node.children.length === 0) {
        node.label=node.name
        node.value=node.deptId
        delete node.disabled
        
        delete node.children
      }else{
            delete node.disabled
            node.label=node.name
            node.value=node.deptId
      }
      
      // 如果当前节点有 children，则递归处理每个子节点
      if (node.children) {
            node.children.forEach(child => {
            delete node.disabled
            node.label=node.name
            node.value=node.deptId
          processNode(child);
        });
      }else{
            delete node.disabled
            node.label=node.name
            node.value=node.deptId
      }
    }
  
    // 开始处理树形数据的每个节点
    tree.forEach(node => {
        node.label=node.name
        node.value=node.deptId
      processNode(node);
    });
  
    // 返回修改后的树形数据
    return tree;
  }
  export function removeEmptyChildrenJob(tree) {
    // 递归函数，用于处理每个节点
    function processNode(node) {
      // 如果当前节点的 children 数组为空，则从 tree 中移除当前节点
      if (node.children && node.children.length === 0) {
        node.label=node.name
        node.value=node.id   
        delete node.children
      }else{
            node.label=node.name
            node.value=node.id
      }
      
      // 如果当前节点有 children，则递归处理每个子节点
      if (node.children) {
            node.children.forEach(child => {
            node.label=node.name
            node.value=node.id
          processNode(child);
        });
      }else{
            node.label=node.name
            node.value=node.id
      }
    }
  
    // 开始处理树形数据的每个节点
    tree.forEach(node => {
        node.label=node.name
        node.value=node.id
        if(!node.deptPathName){
          node.deptPathName=node.name
        }
      processNode(node);
    });
  
    // 返回修改后的树形数据
    return tree;
  }
  export function removeEmptyChildrenMenu(tree) {
    // 递归函数，用于处理每个节点
    function processNode(node) {
      // 如果当前节点的 children 数组为空，则从 tree 中移除当前节点
      if (node.children && node.children.length === 0) {
        node.label=node.menuName
        node.value=node.menuCode	
        // delete node.disabled
        
        delete node.children
      }else{
             node.label=node.menuName
            node.value=node.menuCode
      }
      
      // 如果当前节点有 children，则递归处理每个子节点
      if (node.children) {
            node.children.forEach(child => {
                node.label=node.menuName
                node.value=node.menuCode
          processNode(child);
        });
      }else{
        node.label=node.menuName
        node.value=node.menuCode
      }
    }
  
    // 开始处理树形数据的每个节点
    tree.forEach(node => {
        node.label=node.menuName
        node.value=node.menuCode
      processNode(node);
    });
  
    // 返回修改后的树形数据
    return tree;
  }
  // 根据ID查找节点的函数
export function findNodeById(tree, id) {
  for (const node of tree) {
    if (node.id === id) {
      return node; // 找到匹配的节点，返回它
    }
    // 如果节点有子节点，递归查找
    if (node.children) {
      const foundNode = findNodeById(node.children, id);
      if (foundNode) {
        return foundNode; // 如果在子节点中找到匹配的节点，返回它
      }
    }
  }
  return null; // 如果没有找到匹配的节点，返回null
}
export function flatToTreeHome(data) {
  if (!Array.isArray(data)) return [];
  const treeData = [
    {
      children: [],
      menuName: '我的收藏',
      menuCode: '',
      parentId: -1,
    },
    {
      children: [],
      menuName: '全部系统',
      menuCode: '',
      parentId: -2,
    },
  ];
  const favoritesItems = [];
  const otherItems = [];
  data.forEach(item => {
    if (item.favorites) {
      favoritesItems.push(item);
    } 
     if (item.parentId !== 0) {
      otherItems.push(item);
    }
  });

  treeData[0].children.push(...favoritesItems.sort((a, b) => {
    const aTimeStamp = a.favoritesTimeStamp || 0;
    const bTimeStamp = b.favoritesTimeStamp || 0;
    return aTimeStamp - bTimeStamp;
  }));

  treeData[1].children.push(...otherItems);

  return treeData;
}
//树形数据如何先根据最低一级的所有isCheck=true时，逐级给他的上一级isCheck赋值true，否则赋值false
export function updateParentCheckState(treeData) {
  function traverseAndUpdate(node) {
    // 如果有子节点，则递归遍历子节点
    if (node.children && node.children.length > 0) {
      node.children.forEach(traverseAndUpdate);
      
      // 检查所有子节点的isCheck状态
      const allChildrenChecked = node.children.every(child => child.isCheck);
      // 根据子节点的状态更新当前节点的isCheck
      node.isCheck = allChildrenChecked;
    }
  }

  // 遍历树形数据，更新每个节点的isCheck状态
  treeData.forEach(traverseAndUpdate);
  return treeData
}