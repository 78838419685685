import axios from '../utils/axios'

let baseURL = '/sysMenuUrl'

// 菜单列表
export function getQuerySysMenuUrl(params) {
    return axios.get(baseURL+'/querySysMenuUrl', {params})
}
// 详情
export function querySysMenuTreeApi(params) {
    return axios.get(baseURL+'/querySysMenuTree', {params})
}
// 新增
export function addSysMenuUrlApi(params) {
    return axios.post(baseURL+'/addSysMenuUrl', params)
}
// 编辑
export function editSysMenuUrlApi(params) {
    return axios.post(baseURL+'/editSysMenuUrl', params)
}