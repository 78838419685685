<template>
    <div class="main">
       <div class="header">
        <div>
                系统名称：<span>{{ titleForm.systemName }}</span>
                状态：<span>{{ titleForm.status==="1"?'有效':"无效" }}</span>
            </div>
            <div>
                <el-button  size="mini" @click="$router.go(-1)">返回</el-button>
            </div>
       </div>
       <div class="content">
            <ul class="content_left">
                <li v-if="tabMenuList.menuName" class="active"  @click="activeHandle">
                    <el-tooltip class="item" effect="dark" :content="tabMenuList.menuName" placement="top-start">
                            <span>{{tabMenuList.menuName}}</span>
                        </el-tooltip>
                </li>
            </ul>
            <div class="content_right">
                <el-tree :data="treeList" :props="defaultProps"></el-tree>
            </div>
       </div>
    </div>
</template>
<script>
import {
    removeEmptyChildrenMenu
 } from '@/utils/commen'
import { 
    querySysMenuTreeApi
} from "@/api/menu.js";
export default {
    name: "detail",
    data() {
        return {
            tabMenuList:{},
            treeList:[],
            titleForm:{},
           defaultExpandedKeysList:[2,3], // 展开父节点ID
           defaultCheckedKeysList:[5], // 选中子节点id
           isAllChecked: false, // 用于控制全选框的选中状态
           currentIndex:0,
           defaultProps: {
                children: 'children',
                label: 'label'
            },
            sysMenuId:''
        };
    },
    created() {
        this.titleForm=this.$route.query
        this.sysMenuId=this.titleForm.id
        this.querySysMenuTreeApiList()
    },
    methods: {
    async    querySysMenuTreeApiList(){
            try {
                let res=await querySysMenuTreeApi({sysMenuId:this.sysMenuId})
                if(res.code===0){
                    this.tabMenuList=res.data
                    this.treeList= removeEmptyChildrenMenu(res.data?.menuTree||[])
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });  
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: `${error||'操作失败!'}`
                });  
            }
        },
        changeValue(m,n,z){
        },
        activeHandle(row,i){
            // this.currentIndex=i
        },
        handleCheckAllChange(){

        },
        saveHandle(){
        }
    },
};
</script>
<style lang="scss" scoped>
.main{
    width:100%;
    .header{
        display:flex;
        margin: 10px;
        justify-content:space-between;
        font-size: 14px;
        span{
            margin: 0 20px;
            color: rgb(139, 139, 139);
        }
    }
    .content{
        border: 1px solid rgb(218, 218, 218);
        width: 100%;
        min-height: 500px;
        display:flex;
        .content_left{
            width: 180px;
            font-size: 14px;
            // background: red;
            li{
                height: 40px;
                line-height: 40px;
                padding-left: 10px;
                white-space: nowrap; /* 确保文本不换行 */
                overflow: hidden; /* 隐藏超出容器宽度的文本 */
                text-overflow: ellipsis; /* 显示省略标记 */
            }
            li:hover{
                cursor: pointer;
            }
            .active{
                background: $color-theme-light;
                transition:  .3s ease;
            }
        }
        .content_right{
            width: 100%;
           padding: 10px;
           max-height: 500px;
           overflow: auto;
        }
    }
}
</style>